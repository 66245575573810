import PropTypes from 'prop-types'
import React from 'react'
import spotify_logo from '../images/spotify_green.png'
import soundcloud_logo from '../images/soundcloud_white.png'
import youtube_logo from '../images/youtube_white.png'
import facebook_logo from '../images/facebook.png'
import instagram_logo from '../images/instagram.png'
import gigmit_logo from '../images/gigmit.png'
import logo from '../images/logo.png'


class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >

        <article
          id="listen"
          className={`${this.props.article === 'listen' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Listen</h2>
          <div className="icon">
            <a href="https://open.spotify.com/artist/5cTvr7WsXE98EuQQnDQqkV">
              <img src={spotify_logo} alt="spotify" />
            </a>
          </div>
          <div className="icon">
            <a href="https://soundcloud.com/user-554178976">
              <img src={soundcloud_logo} alt="soundcloud" />
            </a>
          </div>
          {close}
        </article>

        <article
          id="see"
          className={`${this.props.article === 'see' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">See</h2>
          <div className="icon youtube">
            <a href="https://www.youtube.com/watch?v=v82Ez6E6_-I">
              <img src={youtube_logo} alt="youtube" />
            </a>
          </div>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contacts</h2>
          <div className="icon">
            <a href="https://facebook.com/carsonmarsofficial">
              <img src={facebook_logo} alt="facebook" />
            </a>
          </div>
          <div className="icon">
            <a href="https://instagram.com/carsonmarsofficial">
              <img src={instagram_logo} alt="instagram" />
            </a>
          </div>
          <div className="icon">
            <a href="https://www.gigmit.com/cars-on-mars">
              <img src={gigmit_logo} alt="gigmit" />
            </a>
          </div>

          {close}
        </article>

      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
